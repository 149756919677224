<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">{{ eventLocal.id ? 'Dettagli Evento' : '' }}</h4>
          <div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <b-row class="p-2" v-if="eventLocal.id">
          <b-col class="mb-3 text-center" cols="6">
            <p class="mb-1">ID:</p>
            <h3 class="mb-1">#{{ eventLocal.id }}</h3>
          </b-col>
          <b-col v-if="eventLocal.extendedProps && eventLocal.extendedProps.calendar" class="mb-3 text-center" cols="6">
            <p class="mb-1">Tipo:</p>
            <h3 class="mb-1">
              <div
                class="rounded-circle d-inline-block mr-50"
                :class="calendarColors[eventLocal.extendedProps.calendar]"
                style="height: 10px; width: 10px"
              />
              {{ eventLocal.extendedProps.calendar }}
            </h3>
          </b-col>
          <b-col v-if="eventLocal.title && eventLocal.extendedProps.calendar !== 'Giustificativi'" class="mb-3 text-center" cols="12">
            <p class="mb-1">Nome:</p>
            <h3 class="mb-1">{{ eventLocal.title }}</h3>
          </b-col>
          <b-col class="mb-3 text-center" cols="12" v-if="eventLocal.extendedProps.justification">
            <p class="mb-1">Giustificativo:</p>
            <b-link :to="{ name: 'apps-justifications-list' }">
              <h3 class="mb-1 text-info">
                <feather-icon icon="ClipboardIcon" size="25" />
                {{ eventLocal.title }}
              </h3>
            </b-link>
          </b-col>
          <b-col v-if="eventLocal.start" class="mb-3 text-center" cols="12">
            <p class="mb-1">Data Inizio:</p>
            <h3 class="mb-1">
              <feather-icon icon="CalendarIcon" size="25" />
              {{ eventLocal.allDay ? parseDate(eventLocal.start) : parseDateTime(eventLocal.start) }}
            </h3>
          </b-col>
          <b-col v-if="eventLocal.end" class="mb-3 text-center" cols="12">
            <p class="mb-1">Data Fine:</p>
            <h3 class="mb-1" v-if="eventLocal.end != eventLocal.start">
              <feather-icon icon="CalendarIcon" size="25" />
              {{ eventLocal.allDay ? parseDateEnd(eventLocal.end) : parseDateTime(eventLocal.end) }}
            </h3>
            <h3 class="mb-1" v-if="eventLocal.end == eventLocal.start">
              <feather-icon icon="CalendarIcon" size="25" />
              Tutto il Giorno
            </h3>
          </b-col>
          <b-col class="mb-3 text-center" cols="12" v-if="eventLocal.extendedProps.vehicle">
            <p class="mb-1">Veicolo:</p>
            <b-link :to="{ name: 'apps-vehicles-view', params: { id: eventLocal.extendedProps.vehicle.id } }">
              <h3 class="mb-1 text-warning">
                <feather-icon icon="TruckIcon" size="25" />
                {{ eventLocal.extendedProps.vehicle.display_name || 'Nome Veicolo' }}
              </h3>
            </b-link>
            <h6>
              #{{ eventLocal.extendedProps.vehicle.id }} - {{ eventLocal.extendedProps.vehicle.model }} -
              {{ eventLocal.extendedProps.vehicle.factory_year }}
            </h6>
          </b-col>
          <b-col class="mb-3 text-center" cols="12" v-if="eventLocal.extendedProps.headquarter_from">
            <p class="mb-1">Sede di Partenza:</p>
            <b-link
              :to="{ name: 'apps-headquarters-view', params: { id: eventLocal.extendedProps.headquarter_from.id } }"
            >
              <h3 class="mb-1 text-success">
                <feather-icon icon="MapPinIcon" size="25" />
                {{ eventLocal.extendedProps.headquarter_from.name }}
              </h3>
            </b-link>
            <h6>
              #{{ eventLocal.extendedProps.headquarter_from.id }} -
              {{ eventLocal.extendedProps.headquarter_from.address_street }}
            </h6>
          </b-col>
          <b-col class="mb-3 text-center" cols="12" v-if="eventLocal.extendedProps.headquarter_to">
            <p class="mb-1">Sede di Arrivo:</p>
            <b-link
              :to="{ name: 'apps-headquarters-view', params: { id: eventLocal.extendedProps.headquarter_to.id } }"
            >
              <h3 class="mb-1 text-success">
                <feather-icon icon="MapPinIcon" size="25" />
                {{ eventLocal.extendedProps.headquarter_to.name }}
              </h3>
            </b-link>
            <h6>
              #{{ eventLocal.extendedProps.headquarter_to.id }} -
              {{ eventLocal.extendedProps.headquarter_to.address_street }}
            </h6>
          </b-col>
          <b-col class="mb-3 text-center" cols="12" v-if="eventLocal.extendedProps.meeting_room">
            <p class="mb-1">Sala Riunioni:</p>
            <b-link :to="{ name: 'apps-meetingrooms-view', params: { id: eventLocal.extendedProps.meeting_room.id } }">
              <h3 class="mb-1 text-danger">
                <feather-icon icon="MonitorIcon" size="25" />
                {{ eventLocal.extendedProps.meeting_room.name }}
              </h3>
            </b-link>
            <h6>
              #{{ eventLocal.extendedProps.meeting_room.id }} - <feather-icon icon="UserIcon" size="15" />
              {{ eventLocal.extendedProps.meeting_room.max_persons }}
            </h6>
          </b-col>
          <b-col class="mb-3 text-center" cols="12" v-if="eventLocal.extendedProps.headquarter">
            <p class="mb-1">Sede:</p>
            <b-link :to="{ name: 'apps-headquarters-view', params: { id: eventLocal.extendedProps.headquarter.id } }">
              <h3 class="mb-1 text-success">
                <feather-icon icon="MapPinIcon" size="25" />
                {{ eventLocal.extendedProps.headquarter.name }}
              </h3>
            </b-link>
            <h6>
              #{{ eventLocal.extendedProps.headquarter.id }} -
              {{ eventLocal.extendedProps.headquarter.address_street }}
            </h6>
          </b-col>
          <b-col class="text-center" cols="12" v-if="eventLocal.extendedProps.guests.length > 0">
            <p class="mb-1">Partecipanti:</p>
            <b-list-group>
              <b-list-group-item
                v-for="participant in eventLocal.extendedProps.guests"
                :key="participant.id"
                @click="goToParticipantPage(participant)"
                class="d-flex align-items-center justify-content-between"
              >
                <h6 class="mb-0">{{ participant.display_name || 'Nome Utente' }}</h6>
                <feather-icon :icon="getParticipantIcon(participant)" class="text-primary" />
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>

        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm"> </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import moment from 'moment'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BLink,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    moment,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(
      resetEventLocal,
      props.clearEventData
    )

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      calendarColors: {
        Generale: 'bg-success',
        Veicoli: 'bg-warning',
        Riunioni: 'bg-danger',
        Giustificativi: 'bg-info'
      },
    }
  },
  methods: {
    parseDateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    parseDateEnd(date) {
      return moment(date).subtract(1,'day').format('DD/MM/YYYY')
    },  
    parseDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    goToParticipantPage(participant) {
      if (participant.user_id) {
        return this.$router.push({ name: 'apps-users-view', params: {id: participant.user_id} })
      }
      if (participant.contact_id) {
        return this.$router.push({ name: 'apps-contacts-view', params: {id: participant.contact_id}})
      }
      return
    },
    getParticipantIcon(participant) {
      if (participant.user_id) return 'UserIcon'
      if (participant.contact_id) return 'BookIcon'
      return 'MailIcon'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
